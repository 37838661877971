<template>
    <main>
        <HeaderTab :title="$t('global.gestion_residences')" />

		<div id="content">
            <ShutterPanel />
        </div>
    </main>
</template>


<script type="text/javascript">
	import ShutterPanel from 'GroomyRoot/components/Utils/ShutterPanel'
    import Shutter from '@/mixins/Shutter.js'
    import ShutterMvt from '@/mixins/shutters-manager/Mouvements.js'

    export default {
		name: "Localisation",
		mixins: [Shutter, ShutterMvt],
        components: {
            HeaderTab: () => import('@/components/HeaderTab'),
            ShutterPanel
		},
		mounted() {
			this.init_component()
		},
		methods: {
			init_component() {
				this.openCarnetAdresse({ 
					can_chose_location: false,
					can_manage_registry_gen: true
				})
			}
		}
    }
</script>
